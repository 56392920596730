export const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        maxWidth: '85vw',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
