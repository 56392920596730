// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Card = styled.div`
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.067);
    border-radius: 0.66rem;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.067);
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 0 0.66rem 0;
    padding: 0.667rem 1.667rem;
    user-select: none;
    ${({ alttheme }) =>
        alttheme === 'true' &&
        css`
            background-color: transparent;
            align-items: center;
            border: none;
            box-shadow: none;
            margin-bottom: 0;
        `}
`;

const Value = styled.span`
    color: ${(props) => props.theme.colors.text.default};
    font-family: 'Roboto Mono', monospace;
    font-size: 2.5rem;
    font-weight: 400;
    margin: 0;
    text-align: center;
    ${({ alttheme }) =>
        alttheme === 'true' &&
        css`
            /* color: #5e8322; */
            /* color: #4a69bd; */
            color: ${({ theme }) => theme.colors.brand.primary};
            margin-bottom: 0;
        `}
`;

const Title = styled.span`
    color: ${(props) => props.theme.colors.text.default};
    font-size: 1rem;
    font-weight: 400;
    margin: 0.66rem 0;
    ${({ alttheme }) =>
        alttheme === 'true' &&
        css`
            /* color: #5e8322; */
            margin-top: 0;
            margin-bottom: 0;
        `}
`;

export const StatCard = ({ value, title, alttheme, ...props }) => {
    return (
        <Card alttheme={alttheme.toString()}>
            <Value alttheme={alttheme.toString()}>
                {value.toLocaleString()}
            </Value>
            <Title alttheme={alttheme.toString()}>{title}</Title>
        </Card>
    );
};

StatCard.propTypes = {
    title: PropTypes.string,
    alttheme: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default StatCard;
