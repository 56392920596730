import React from 'react';
import Modal from 'react-modal';
import { httpsCallable } from 'firebase/functions';
import { useMutation, useQueryClient } from '@tanstack/react-query';

// Contexts
import { FirebaseContext } from '../../contexts/Firebase';

// Components
import { Button, BUTTON_TYPES } from '../Button';
import { Input } from '../Input';
import { ContentRow } from '../ContentRow';

// Local Components
import { ModalTitle } from './components/ModalTitle';

// Modal Styles
import { customModalStyles } from './styles/customModalStyles';

export const ResetPollModal = ({ open, onClose, pollName, pollId }) => {
    const { functions } = React.useContext(FirebaseContext);
    const queryClient = useQueryClient();

    const [pollNameInput, setPollNameInput] = React.useState('');
    const [displayValidationMessage, setDisplayValidationMessage] =
        React.useState(false);
    const isEqualPollName = React.useCallback(
        (data = '') => {
            return data === pollName;
        },
        [pollName]
    );
    const clearVotes = httpsCallable(functions, 'clearVotes');
    const clearVotesMutation = useMutation(clearVotes, {
        onSuccess: async (data) => {
            queryClient.refetchQueries('polls');
            onClose();
        },
        onError: (err) => {
            console.log('reset err: ', err);
        }
    });

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => {
                onClose?.();
                setPollNameInput('');
                setDisplayValidationMessage(false);
            }}
            style={customModalStyles}
            contentLabel="Reset Poll?"
        >
            <ModalTitle>Reset Poll?</ModalTitle>
            <p>Resetting this poll will discard all recorded votes.</p>
            <p>
                To confirm the poll reset, please type the poll name (
                <strong>{pollName}</strong>) into the text input below.
            </p>
            <ContentRow>
                <Input
                    label={'Poll name'}
                    value={pollNameInput}
                    onChange={(e) => {
                        setPollNameInput(e.target.value);
                        if (isEqualPollName(e.target.value)) {
                            setDisplayValidationMessage(false);
                        }
                    }}
                    validationMessage={
                        !displayValidationMessage
                            ? null
                            : 'Please enter the poll name to authenticate reset.'
                    }
                />
            </ContentRow>
            <ContentRow
                style={{ flexDirection: 'row-reverse', marginTop: '1rem' }}
            >
                <Button
                    type={BUTTON_TYPES.DESTRUCTIVE}
                    disabled={!pollNameInput || clearVotesMutation.isLoading}
                    onClick={() => {
                        if (!isEqualPollName(pollNameInput)) {
                            setDisplayValidationMessage(true);
                        } else {
                            // clearVotes({ pollId: pollId });
                            clearVotesMutation.mutate({ pollId: pollId });
                        }
                    }}
                >
                    {clearVotesMutation.isLoading ? 'Resetting...' : 'Reset'}
                </Button>
            </ContentRow>
        </Modal>
    );
};
