// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import DefaultLayout from './../../layouts/Default';
import Voting from '../../components/Voting';

const ResultsAndInstructionsPage = ({ location }) => {
    return (
        <DefaultLayout location={location}>
            <Helmet title={`Results & Instructions`} />
            <Voting withResults />
        </DefaultLayout>
    );
};

ResultsAndInstructionsPage.propTypes = {
    location: PropTypes.object
};

export default ResultsAndInstructionsPage;
