// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';

import AwardIllustration from './../../Illustrations/Award';
import VotingQRCode from './../../Illustrations/VotingQRCode';

const InstructionCard = styled.div`
    /* background-color: ${(props) => props.theme.colors.background.tertiary};
    border: 2px solid ${(props) => props.theme.colors.border.tertiary}; */
    border-radius: 0.66rem;
    /* box-shadow: 0 2px 0 rgba(0, 0, 0, 0.067); */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${({ noMargin }) => (noMargin ? 0 : '1rem 0 2.33rem 0')};
    padding: 0.66rem 0.66rem 1.66rem 0.66rem;
    user-select: none;
    ${(props) => props.theme.responsive.sff} {
        margin: 0.66rem 0 1.66rem 0;
    }
`;

const Illustration = css`
    height: ${({ autoheight }) => (autoheight === 'true' ? 'auto' : '15rem')};
    max-height: ${({ autoheight }) =>
        autoheight === 'true' ? '10rem' : 'auto'};
    margin: 0 0 1.66rem 0;
    ${(props) => props.theme.responsive.sff} {
        height: auto;
        margin: 0 0 1.66rem 0;
    }
    ${({ desktoponly }) =>
        desktoponly === 'true' &&
        css`
            display: none;
            ${(props) => props.theme.responsive.desktop} {
                display: block;
            }
        `}
    ${({ mobileonly }) =>
        mobileonly === 'true' &&
        css`
            display: none;
            ${(props) => props.theme.responsive.device} {
                display: block;
            }
        `}
`;

const AwardPic = styled(({ ...rest }) => <AwardIllustration {...rest} />)`
    ${Illustration}
`;

const QRCode = styled(({ ...rest }) => <VotingQRCode {...rest} />)`
    ${Illustration}
`;

const FlexRow = styled.div`
    ${(props) => props.theme.responsive.desktop} {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }
    ${({ isColumn }) =>
        isColumn &&
        css`
            flex-direction: column !important;
            align-items: center !important;
            justify-content: center !important;
        `}
`;

const FlexBlock = styled(({ ...rest }) => <div {...rest} />)`
    ${(props) => props.theme.responsive.desktop} {
        display: flex;
        flex-basis: 50%;
        flex-direction: column;
    }
`;

const Heading = styled(({ desktopOnly, ...rest }) => <h1 {...rest} />)`
    color: ${(props) => darken(0.3, props.theme.colors.brand.primary)};
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    text-align: center;
    ${(props) => props.theme.responsive.sff} {
        font-size: 1rem;
    }
    span {
        ${(props) => props.theme.responsive.device} {
            margin: ${(props) => (props.desktopOnly ? `0.667rem 0 0 0` : `0`)};
            display: ${(props) => (!props.desktopOnly ? `block` : `none`)};
            visibility: ${(props) =>
                !props.desktopOnly ? `visible` : `hidden`};
        }
        font-size: 1.867rem;
        margin: 0.667rem 0 0 0;
    }
`;

const VotingPhoneNumber = styled.h2`
    color: ${(props) => props.theme.colors.brand.primary};
    font-family: 'Roboto Mono', monospace;
    font-size: 1.867rem;
    font-weight: 400;
    margin: 0.66rem 0 0 0;
    text-align: center;
    ${(props) => props.theme.responsive.sff} {
        font-size: 1.667rem;
    }
`;

export const VotingInstuctions = ({
    number,
    votingEnabled,
    narrow = false
}) => {
    // Format the number for display
    let formattedNumber = React.useMemo(() => {
        const str = String(number);

        return (str.length > 10 ? str.substr(1) : str)
            .replace(/[^\d]/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }, [number]);

    return votingEnabled ? (
        <InstructionCard noMargin={narrow}>
            <QRCode autoheight={narrow.toString()} desktoponly="true" />
            <AwardPic autoheight={narrow.toString()} mobileonly="true" />
            <FlexRow isColumn={narrow}>
                <FlexBlock style={{ marginBottom: '1rem' }}>
                    <Heading>
                        To vote for your favorite pitch,
                        <br />
                        text their code to:
                    </Heading>
                    <VotingPhoneNumber>{formattedNumber}</VotingPhoneNumber>
                </FlexBlock>
                <FlexBlock>
                    <Heading desktopOnly>
                        Or tap to vote from
                        <br />
                        your mobile device:
                        <br />
                        <span>
                            <VotingPhoneNumber>
                                vote.ideafunding.org
                            </VotingPhoneNumber>
                        </span>
                    </Heading>
                </FlexBlock>
            </FlexRow>
        </InstructionCard>
    ) : (
        <InstructionCard noMargin={narrow}>
            <AwardPic />
            <Heading>
                Voting isn&apos;t available right now,
                <br />
                please check back shortly!
            </Heading>
        </InstructionCard>
    );
};

VotingInstuctions.propTypes = {
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    votingEnabled: PropTypes.bool,
    narrow: PropTypes.bool
};

export default VotingInstuctions;
