// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { httpsCallable } from 'firebase/functions';
import { useMutation, useQueryClient } from '@tanstack/react-query';

// Contexts
import { FirebaseContext } from '../../../contexts/Firebase';

// Components
import { Button, BUTTON_TYPES } from '../../Button';
import { ConfirmationModal } from '../../Modals/ConfirmationModal';

const CustomButton = styled(Button)`
    width: 5rem;
`;

export const PollStatusButton = ({
    name,
    id,
    votingEnabled,
    enabledPollExists
}) => {
    const { functions } = React.useContext(FirebaseContext);
    const queryClient = useQueryClient();

    const [showChangeActivePollModal, setShowChangeActivePollModal] =
        React.useState(false);
    const [showDeactivatePollModal, setShowDeactivatePollModal] =
        React.useState(false);
    const [showActivatePollModal, setShowActivatePollModal] =
        React.useState(false);
    const onEnable = React.useCallback(() => {
        if (enabledPollExists) {
            setShowChangeActivePollModal(true);
        } else {
            setShowActivatePollModal(true);
        }
    }, [enabledPollExists, setShowChangeActivePollModal]);
    const toggleVoting = httpsCallable(functions, 'toggleVoting');
    const togglePollMutation = useMutation(toggleVoting, {
        onSuccess: async (data) => {
            queryClient.refetchQueries('polls');
            setShowChangeActivePollModal(false);
            setShowDeactivatePollModal(false);
            setShowActivatePollModal(false);
        },
        onError: (err) => {
            console.log('reset err: ', err);
        }
    });

    return (
        <>
            {votingEnabled ? (
                <CustomButton
                    type={BUTTON_TYPES.WARNING}
                    onClick={() => {
                        setShowDeactivatePollModal(true);
                    }}
                >
                    Disable
                </CustomButton>
            ) : (
                <CustomButton type={BUTTON_TYPES.PRIMARY} onClick={onEnable}>
                    Enable
                </CustomButton>
            )}
            {showChangeActivePollModal ? (
                <ConfirmationModal
                    open={true}
                    title={'Change active poll?'}
                    message={
                        <>
                            Looks like there is another poll which is currently
                            active.
                            <br />
                            Enabling this poll will deactivate the currently
                            active poll. Do you wish to continue?
                        </>
                    }
                    yesButtonLabel={
                        togglePollMutation.isLoading
                            ? 'Enabling...'
                            : `Yes, enable ${name} poll`
                    }
                    onYes={() => {
                        togglePollMutation.mutate({ pollId: id });
                    }}
                    onClose={() => {
                        setShowChangeActivePollModal(false);
                    }}
                />
            ) : null}
            {showDeactivatePollModal ? (
                <ConfirmationModal
                    open={true}
                    title={'Deactivate poll?'}
                    message={
                        <>
                            Once a poll has been deactivated{' '}
                            <strong>no more votes will be recorded</strong>.
                            <br />
                            Do you still wish to disable this poll?
                        </>
                    }
                    yesButtonLabel={
                        togglePollMutation.isLoading
                            ? 'Disabling...'
                            : `Yes, disable poll`
                    }
                    onYes={() => {
                        togglePollMutation.mutate({
                            pollId: id
                        });
                    }}
                    onClose={() => {
                        setShowDeactivatePollModal(false);
                    }}
                />
            ) : null}
            {showActivatePollModal ? (
                <ConfirmationModal
                    open={true}
                    title={'Activate poll?'}
                    message={
                        <>
                            Once a poll has been activated{' '}
                            <strong>voting will be open</strong>.
                            <br />
                            Do you still wish to enable this poll?
                        </>
                    }
                    yesButtonLabel={
                        togglePollMutation.isLoading
                            ? 'Enabling...'
                            : `Yes, enable poll`
                    }
                    onYes={() => {
                        togglePollMutation.mutate({
                            pollId: id
                        });
                    }}
                    onClose={() => {
                        setShowActivatePollModal(false);
                    }}
                />
            ) : null}
        </>
    );
};

PollStatusButton.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    votingEnabled: PropTypes.bool,
    enabledPollExists: PropTypes.bool
};

export default PollStatusButton;
