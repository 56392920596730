import React from 'react';
import Modal from 'react-modal';

// Components
import { Button, BUTTON_TYPES } from '../Button';
import { ContentRow } from '../ContentRow';

// Local Components
import { ModalTitle } from './components/ModalTitle';

// Modal Styles
import { customModalStyles } from './styles/customModalStyles';

export const ConfirmationModal = ({
    open,
    onClose,
    title,
    message,
    onYes,
    yesButtonLabel
}) => {
    return (
        <Modal
            isOpen={open}
            onRequestClose={() => {
                onClose?.();
            }}
            style={customModalStyles}
            contentLabel={title}
        >
            <ModalTitle>{title}</ModalTitle>
            <p style={{ marginBottom: '2rem' }}>{message}</p>
            <ContentRow
                style={{ flexDirection: 'row-reverse', marginTop: '1rem' }}
            >
                <Button
                    type={BUTTON_TYPES.PRIMARY}
                    onClick={() => {
                        onYes();
                    }}
                >
                    {yesButtonLabel}
                </Button>
                <Button type={BUTTON_TYPES.DEFAULT} onClick={onClose}>
                    Cancel
                </Button>
            </ContentRow>
        </Modal>
    );
};
