import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';

export const BUTTON_TYPES = {
    PRIMARY: 'primary',
    DEFAULT: 'default',
    WARNING: 'warning',
    DESTRUCTIVE: 'destructive'
};

export const Button = styled.button`
    font-weight: 700;
    color: white;
    border: none;
    border-radius: 4px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #9d9d9d;
    background: #fff;
    border: 1px solid #9d9d9d;
    transition: color 0.3s ease, background 0.3s ease;
    &:hover {
        color: #7d7d7d;
        background: #f5f5f5;
    }
    ${({ type }) => {
        switch (type) {
            case BUTTON_TYPES.PRIMARY:
                return css`
                    color: #fff;
                    background: ${({ theme }) => theme.colors.brand.primary};
                    border-color: ${({ theme }) => theme.colors.brand.primary};
                    &:hover {
                        color: #fff;
                        background: ${({ theme }) =>
                            darken(0.1, theme.colors.brand.primary)};
                    }
                `;
            case BUTTON_TYPES.WARNING:
                return css`
                    color: #fff;
                    background: #ffab46;
                    border-color: #ffab46;
                    &:hover {
                        color: #fff;
                        background: #ff9210;
                    }
                `;
            case BUTTON_TYPES.DESTRUCTIVE:
                return css`
                    color: #fff;
                    background: #e77152;
                    border-color: #e77152;
                    &:hover {
                        color: #fff;
                        background: #de3434;
                    }
                `;
            default:
                return;
        }
    }}
    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
            opacity: 0.5;
        `}
`;
