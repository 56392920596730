// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const ProgressContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.067);
    border-radius: 0.66rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    height: 10px;
    margin: 0.33rem 0 0 0;
    overflow: hidden;
`;

const ProgressBar = styled(({ width, ...rest }) => <div {...rest} />)`
    background-color: ${(props) => props.theme.colors.brand.primary};
    flex-basis: ${(props) => props.width || 0}%;
    ${({ alttheme }) =>
        alttheme === 'true' &&
        css`
            /* background-color: #4a69bd; */
            background-color: ${({ theme }) => theme.colors.brand.primary};
        `}
`;

export const ResultsProgressIndicator = ({ ratio, alttheme }) => {
    return (
        <ProgressContainer>
            <ProgressBar width={ratio} alttheme={alttheme.toString()} />
        </ProgressContainer>
    );
};

ResultsProgressIndicator.propTypes = {
    ratio: PropTypes.number,
    alttheme: PropTypes.bool
};

export default ResultsProgressIndicator;
