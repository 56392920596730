// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemeContext } from 'styled-components';
import AliceCarousel from 'react-alice-carousel';

import Button from './Button';

const VotingOptionsCont = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const VotingOptionsTitle = styled.h2`
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.brand.primary};
`;

const VotingOptionGrid = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: space-between;
    justify-content: space-between;
    ${(props) => props.theme.responsive.desktop} {
        justify-content: center;
    }
    ${({ fullWidth }) =>
        fullWidth &&
        css`
            width: 100%;
        `}
    ${({ alttheme }) =>
        alttheme &&
        css`
            /* border-radius: 10px;
            border: ${({ theme }) =>
                `2px solid ${theme.colors.border.tertiary}`};
            background: ${({ theme }) => theme.colors.background.tertiary};
            border-radius: 10px; */
        `}
`;

/**
 * Shuffles array in place. Courtesy of https://stackoverflow.com/a/6274381
 * @param {Array} a items An array containing the items.
 */
const shuffleArray = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
};

export const VotingOptions = ({
    number,
    options,
    alttheme = false,
    fullWidth = false,
    withTitle = false,
    carousel = false
}) => {
    const items = React.useMemo(
        () =>
            shuffleArray(options).map((option, i) => (
                <Button
                    key={`voteOpt_${option.shortcode}`}
                    option={{ ...option, number }}
                />
            )),
        [options]
    );

    return (
        <VotingOptionsCont>
            {withTitle ? (
                <VotingOptionsTitle>Voting Options</VotingOptionsTitle>
            ) : null}
            <VotingOptionGrid fullWidth={fullWidth} alttheme={alttheme}>
                {carousel ? (
                    <AliceCarousel
                        items={items}
                        autoPlay={true}
                        autoPlayInterval={2500}
                        infinite={true}
                        disableDotsControls={true}
                        disableButtonsControls={true}
                        autoPlayControls={false}
                        responsive={{
                            0: {
                                items: 1
                            },
                            479: {
                                items: 2
                            },
                            770: {
                                items: 3
                            }
                        }}
                    />
                ) : (
                    items
                )}
            </VotingOptionGrid>
        </VotingOptionsCont>
    );
};

VotingOptions.propTypes = {
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    options: PropTypes.array,
    alttheme: PropTypes.bool,
    fullWidth: PropTypes.bool,
    withTitle: PropTypes.bool,
    carousel: PropTypes.bool
};

export default VotingOptions;
