// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';

// Components
import { Loading } from '../../components/Loading';

// Contexts
import { FirebaseContext } from '../../contexts/Firebase';

// Methods
import { getPolls } from '../../methods/getPolls';

// Local Components
import { PollTableRow } from './components/PollTableRow';

const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ededed;
    border-radius: 4px;
`;

const NoResultsPlaceholder = styled.span`
    text-align: center;
    padding: 2rem;
`;

export const PollsTable = () => {
    const { db } = React.useContext(FirebaseContext);
    const pollsQuery = useQuery(
        ['polls'],
        async () => {
            try {
                const data = await getPolls(db);

                return data;
            } catch (e) {
                console.log(e);
            }
        },
        {
            enabled: typeof db !== 'undefined',
            refetchOnMount: false,
            refetchOnWindowFocus: false
        }
    );
    const enabledPollExists = React.useMemo(
        () => pollsQuery?.data?.some(({ votingEnabled }) => votingEnabled),
        [pollsQuery?.data]
    );
    React.useEffect(() => {
        console.log('pollsQuery?.data', pollsQuery?.data);
    }, [pollsQuery?.data]);

    return (
        <TableContainer>
            <PollTableRow isHeader />
            {pollsQuery.isLoading ? (
                <Loading />
            ) : (
                <>
                    {pollsQuery.data.length ? (
                        pollsQuery.data.map((item, index) => (
                            <PollTableRow
                                {...item}
                                enabledPollExists={enabledPollExists}
                                key={index}
                            />
                        ))
                    ) : (
                        <NoResultsPlaceholder>
                            No polls available.
                        </NoResultsPlaceholder>
                    )}
                </>
            )}
        </TableContainer>
    );
};

PollsTable.propTypes = {};

export default PollsTable;
