// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import { FirebaseContext } from './../../contexts/Firebase';
import { getVotingSettings } from './../../methods/getVotingSettings';
import { getPollConfig } from './../../methods/getPollConfig';
import Loading from './../Loading';
import { LayoutSection } from './../LayoutSection';
import Results from './../Results';
import VotingInstructions from './Instructions';
import VotingOptions from './Options';
import VotingRules from './Rules';
import { CurrentPollTitle } from './../CurrentPollTitle';

const ResultsCard = styled.div`
    padding: 10px;
    border: ${({ theme }) => `2px solid ${theme.colors.background.secondary}`};
    background: ${({ theme }) => theme.colors.background.secondary};
    align-self: start;
    border-radius: 10px;
    max-width: 22rem;
    width: 50vw;
`;

const ResultsCardTitle = styled.h2`
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    /* color: #749c31; */
    /* color: #95be50; */
    /* color: #4a69bd; */
    color: ${({ theme }) => theme.colors.text.default};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
`;

const OptionsCont = styled.div`
    /* ${(props) => props.theme.responsive.device} { */
    margin: 3rem auto 1rem;
    /* } */
`;

// eslint-disable-next-line no-unused-vars
const d = require('debug')('suptuc:Voting');

export const Voting = ({ withResults = false } = {}) => {
    const { db } = React.useContext(FirebaseContext);
    const [error, setError] = React.useState({});
    const votingSettingsQuery = useQuery(
        ['votingSettings'],
        async () => {
            try {
                const votingSettings = await getVotingSettings(db);
                const pollConfig = await getPollConfig(votingSettings);

                return pollConfig;
            } catch (e) {
                console.log(e);
                setError(e);
            }
        },
        {
            enabled: typeof db !== 'undefined',
            refetchOnMount: false,
            refetchOnWindowFocus: false
        }
    );

    if (
        votingSettingsQuery.isLoading ||
        !Object.keys(votingSettingsQuery.data).length
    ) {
        return <Loading />;
    }

    if (Object.keys(error).length) {
        return (
            <pre>
                Something went wrong... please try again.
                <br />
                Error: {JSON.stringify(error, null, 2)}
            </pre>
        );
    }

    return withResults ? (
        <>
            <Row>
                <LayoutSection narrow={true}>
                    <CurrentPollTitle>
                        {votingSettingsQuery.data.name}
                    </CurrentPollTitle>
                    <VotingInstructions
                        narrow={true}
                        votingEnabled={votingSettingsQuery.data?.votingEnabled}
                        number={votingSettingsQuery.data?.phoneNumber}
                    />
                </LayoutSection>
                <div>
                    <ResultsCard>
                        <ResultsCardTitle>Leaderboard</ResultsCardTitle>
                        <Results narrow leaderboard />
                    </ResultsCard>
                    <VotingRules notitle />
                </div>
            </Row>
            <Row>
                {votingSettingsQuery.data?.votingEnabled && (
                    <VotingOptions
                        fullWidth
                        alttheme
                        withTitle
                        carousel
                        number={votingSettingsQuery.data?.phoneNumber}
                        options={votingSettingsQuery.data?.options}
                    />
                )}
            </Row>
        </>
    ) : (
        <LayoutSection>
            <CurrentPollTitle>{votingSettingsQuery.data.name}</CurrentPollTitle>
            <VotingInstructions
                votingEnabled={votingSettingsQuery.data?.votingEnabled}
                number={votingSettingsQuery.data?.phoneNumber}
            />
            {votingSettingsQuery.data?.votingEnabled && (
                <VotingOptions
                    number={votingSettingsQuery.data?.phoneNumber}
                    options={votingSettingsQuery.data?.options}
                />
            )}
            <OptionsCont>
                <VotingRules />
            </OptionsCont>
        </LayoutSection>
    );
};

Voting.propTypes = {
    firebase: PropTypes.object,
    narrow: PropTypes.bool,
    withResults: PropTypes.bool
};

export default Voting;
