// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MainWrapper = styled.main`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 900px;
    margin: 0 auto;
    padding-top: 1.667rem;
    padding-bottom: 1.667rem;
    ${(props) => props.theme.responsive.device} {
        padding-left: 1.337rem;
        padding-right: 1.337rem;
    }
`;

export const Wrapper = ({ ...props }) => {
    return <MainWrapper {...props} />;
};

Wrapper.propTypes = {
    children: PropTypes.node.isRequired
};

export default Wrapper;
