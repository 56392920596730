import { doc, getDoc } from 'firebase/firestore';

// eslint-disable-next-line no-unused-vars
const d = require('debug')('suptuc:getVotingSettings');
// eslint-disable-next-line space-before-function-paren
export const getVotingSettings = async (db) => {
    try {
        const votingSettingsRef = doc(db, 'settings/production');
        const settingsDoc = await getDoc(votingSettingsRef);

        if (settingsDoc.exists()) {
            const votingSettings = settingsDoc.data();
            d('Got votingSettings, %O', votingSettings);
            return votingSettings;
        }
        throw new Error(`votingSettings doesn't exist, or can't be fetched`);
    } catch (err) {
        d('Failed to get /settings/production: %O', err);
        return err;
    }
};
