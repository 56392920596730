// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Container = styled.div`
    padding-top: 1.2rem;
    position: relative;
`;

const CustomLabel = styled.label`
    font-size: 0.9rem;
    position: absolute;
    line-height: 0.9rem;
    top: 0;
    left: 0;
    color: #898989;
`;

const CustomInput = styled.input`
    border: 1px solid #b6b6b6;
    border-radius: 4px;
    padding: 12px 18px;
    &:hover {
        border-color: #898989;
    }
    &:focus,
    &:active {
        border-color: #2b2b2b;
        outline: none;
    }
`;

const ValidationMessage = styled.span`
    margin-top: 6px;
    color: #de3434;
    display: block;
`;

export const Input = ({ validationMessage, label, ...props }) => {
    return (
        <Container>
            <CustomLabel htmlFor={props.id}>{label}</CustomLabel>
            <CustomInput {...props} />
            {validationMessage ? (
                <ValidationMessage>{validationMessage}</ValidationMessage>
            ) : null}
        </Container>
    );
};
