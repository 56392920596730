// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Components
import { Button } from '../../Button';
import { ResetPollModal } from '../../Modals/ResetPollModal';

// Local Components
import { PollStatusButton } from './PollStatusButton';

const Row = styled.div`
    padding: 0.6rem 0.8rem;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    border-top: ${({ theme }) =>
        `1px solid ${theme.colors.background.secondary}`};
    ${({ header }) =>
        header &&
        css`
            border-top: none;
            background: ${({ theme }) => theme.colors.background.tertiary};
            padding: 0.8rem;
        `}
`;

const ActiveFlag = styled.i`
    color: ${({ theme }) => theme.colors.brand.primary};
`;

const ResetCell = styled.div`
    padding: 0 0.4rem;
`;

const CustomButton = styled(Button)`
    width: 5rem;
`;

const StatusButtonCell = styled.div`
    padding: 0 0 0 0.4rem;
`;

const NameCell = styled.div`
    flex: 1;
`;

export const PollTableRow = ({
    name,
    id,
    isHeader,
    totalVotes,
    votingEnabled,
    enabledPollExists
}) => {
    const [showResetModal, setShowResetModal] = React.useState(false);

    return (
        <>
            <Row header={isHeader}>
                <NameCell>
                    {isHeader ? (
                        'Poll name'
                    ) : (
                        <>
                            {name}
                            {/* {votingEnabled ? (
                                <>
                                    &nbsp;<ActiveFlag>(Active)</ActiveFlag>
                                </>
                            ) : (
                                ''
                            )} */}
                        </>
                    )}
                </NameCell>
                {!isHeader ? (
                    <>
                        <ResetCell>
                            <CustomButton
                                onClick={() => {
                                    setShowResetModal(true);
                                }}
                            >
                                Reset
                            </CustomButton>
                        </ResetCell>
                        <StatusButtonCell>
                            <PollStatusButton
                                name={name}
                                id={id}
                                votingEnabled={votingEnabled}
                                enabledPollExists={enabledPollExists}
                            />
                        </StatusButtonCell>
                    </>
                ) : null}
            </Row>
            {showResetModal ? (
                <ResetPollModal
                    open={true}
                    pollId={id}
                    pollName={name}
                    onClose={() => {
                        setShowResetModal(false);
                    }}
                />
            ) : null}
        </>
    );
};

PollTableRow.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    isHeader: PropTypes.bool,
    totalVotes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    votingEnabled: PropTypes.bool,
    enabledPollExists: PropTypes.bool
};

export default PollTableRow;
