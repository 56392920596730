// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Title = styled.h1``;
const Highlight = styled.span`
    color: ${({ theme }) => theme.colors.brand.primary};
`;

export const CurrentPollTitle = ({ children, ...props }) => {
    return (
        <Title>
            <small>Active Poll:</small> <Highlight>{children}</Highlight>
        </Title>
    );
};

CurrentPollTitle.propTypes = {};

export default CurrentPollTitle;
