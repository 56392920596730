// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

import AdminLayout from './../../layouts/Admin';

import { PollsTable } from './../../components/PollsTable';

const AdminPage = ({ location }) => (
    <AdminLayout location={location}>
        <React.Fragment>
            <PollsTable />
        </React.Fragment>
    </AdminLayout>
);

AdminPage.propTypes = {
    location: PropTypes.object
};

export default AdminPage;
