// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled from 'styled-components';

import HivemetricLogo from './../Branding/HivemetricLogo';

const FooterActual = styled.footer`
    border-top: 1px solid #eee;
    background: ${({ theme }) => theme.colors.background.tertiary};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 1.5rem 0;
    justify-content: space-between;
    ${(props) => props.theme.responsive.device} {
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 2rem;
    }
`;

const SponsorGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    ${(props) => props.theme.responsive.device} {
        width: 100%;
    }
`;

const LogoGroup = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 4rem;
    align-items: center;
    gap: 16px;
    ${(props) => props.theme.responsive.device} {
        width: 100%;
        justify-content: space-around;
    }
`;

const PoweredBy = styled.h3`
    color: ${(props) => props.theme.colors.text.muted};
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0;
    text-align: center;
    ${(props) => props.theme.responsive.sff} {
        font-size: 0.9rem;
    }
    user-select: none;
`;

const HiveLogo = styled(HivemetricLogo)`
    width: 10rem;
    ${(props) => props.theme.responsive.sff} {
        width: 10rem;
    }
`;

const StartupTucsonLogo = styled.img`
    height: 4.5rem;
    /* ${(props) => props.theme.responsive.sff} {
        width: 10rem;
    } */
`;

const UAICLogo = styled.img`
    height: 2.5rem;
    /* ${(props) => props.theme.responsive.sff} {
        width: 10rem;
    } */
`;

export const Footer = () => {
    return (
        <FooterActual>
            <ContentWrapper>
                <SponsorGroup>
                    <PoweredBy>IdeaFunding 2023 hosted by</PoweredBy>
                    <LogoGroup>
                        <StartupTucsonLogo
                            src={`${process.env.PUBLIC_URL}/Startup_Logos_2020-Large_Navy.png`}
                        />
                        <UAICLogo
                            src={`${process.env.PUBLIC_URL}/2019_UACI_Logo.png`}
                        />
                    </LogoGroup>
                </SponsorGroup>
                <SponsorGroup>
                    <PoweredBy>Voting powered by</PoweredBy>
                    <LogoGroup>
                        <a
                            href="https://www.hivemetric.com/?utm_source=vote.ideafunding.org&utm_medium=event_sponsorship&utm_campaign=ideafunding2019"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <HiveLogo />
                        </a>
                    </LogoGroup>
                </SponsorGroup>
            </ContentWrapper>
        </FooterActual>
    );
};

Footer.propTypes = {};

export default Footer;
