// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './../../components/Wrapper';
import Header from './../../components/Header';
import Footer from './../../components/Footer';
import { Content } from './../../components/Content';

// eslint-disable-next-line no-unused-vars
const d = require('debug')('suptuc:DefaultLayout');

export const DefaultLayout = ({ children, initialized, location, ...rest }) => {
    /*if (!initialized) {
            return null;
        }*/

    return (
        <>
            <Header />
            <Content>
                <Wrapper {...rest}>{children}</Wrapper>
            </Content>
            <Footer />
        </>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    initialized: PropTypes.bool,
    location: PropTypes.object
};

DefaultLayout.defaultProps = {
    initialized: false
};

export default DefaultLayout;
