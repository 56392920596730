// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

export const HivemetricLogo = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            viewBox="0 0 844 163.3"
            {...props}
        >
            <style>{`.st0{opacity:.85}.st1,.st2{fill:#fff}.st2{opacity:.85}.st3{fill:#00b6fa}.st4{opacity:.85;fill:#fa8200}`}</style>
            <g className="st0">
                <path
                    d="M72 42.1H25.6L2.3 82.3l23.3 40.3H72l23.2-40.3z"
                    className="st1"
                />
            </g>
            <path
                d="M95.2 1.5H48.8L25.6 41.7 48.8 82h46.4l23.2-40.3z"
                className="st2"
            />
            <path
                d="M141.4 1.5H95L71.8 41.7 95 82h46.4l23.2-40.3z"
                className="st2"
            />
            <g className="st0">
                <path
                    d="M94.8 82H48.4l-23.2 40.2 23.2 40.3h46.4l23.2-40.3z"
                    className="st1"
                />
            </g>
            <path
                d="M164.6 42.1h-46.4L95 82.4l23.2 40.2h46.4l23.2-40.2z"
                className="st2"
            />
            <path
                d="M141.6 82H95.2L72 122.2l23.2 40.3h46.4l23.2-40.3z"
                className="st2"
            />
            <g className="st0">
                <path
                    d="M72 42.1H25.6L2.3 82.3l23.3 40.3H72l23.2-40.3z"
                    className="st3"
                />
            </g>
            <path
                d="M95.2 1.5H48.8L25.6 41.7 48.8 82h46.4l23.2-40.3z"
                className="st4"
            />
            <path
                d="M141.4 1.5H95L71.8 41.7 95 82h46.4l23.2-40.3z"
                className="st4"
            />
            <g className="st0">
                <path
                    d="M94.8 82H48.4l-23.2 40.2 23.2 40.3h46.4l23.2-40.3z"
                    className="st3"
                />
            </g>
            <path
                fill="#ff9018"
                d="M164.6 42.1h-46.4L95 82.4l23.2 40.2h46.4l23.2-40.2z"
                opacity=".8"
            />
            <path
                fill="#007ead"
                d="M141.6 82H95.2L72 122.2l23.2 40.3h46.4l23.2-40.3z"
                opacity=".8"
            />
            <g>
                <path
                    d="M275 132.1h-13V74.5h-36.5v57.6h-13V34.2h13v39.7a13.6 13.6 0 0 1 4-8.9 13.5 13.5 0 0 1 9.1-3.7H262a13.4 13.4 0 0 1 9.3 3.8 13.4 13.4 0 0 1 3.8 9.3v57.7zm11.6-97.9H301v13.6h-14.4V34.2zm13.8 27.2v70.7h-13.1V61.4h13.1zm32.3 70.7l-28.3-70.7H318l21.4 55.1L361 61.4h13.6l-28.3 70.7h-13.6zm105.2-28.7h-46.8V119h46.8v13.1h-46.8a13.4 13.4 0 0 1-9.2-3.8 13.4 13.4 0 0 1-3.8-9.3V74.5a13.4 13.4 0 0 1 3.8-9.3 13.4 13.4 0 0 1 9.2-3.8h33.7a13.4 13.4 0 0 1 9.3 3.8 13.4 13.4 0 0 1 3.8 9.3v28.9zm-46.8-28.9v15.9h33.7V74.5h-33.7zm114.4 0v57.6h-13.1V74.5h-29.9v57.6h-13V61.4h13v12.5a13.6 13.6 0 0 1 4-8.9 13.5 13.5 0 0 1 9.1-3.7h16.9c1.7 0 3.4.3 4.9 1 1.5.6 2.9 1.5 4.1 2.7a12.8 12.8 0 0 1 4.1 8.9 13.6 13.6 0 0 1 4-8.9 13.5 13.5 0 0 1 9.1-3.7h16.9a13.4 13.4 0 0 1 9.3 3.8 13.4 13.4 0 0 1 3.8 9.3V132h-13.1V74.5h-30.1zm114.4 28.9h-46.8V119h46.8v13.1h-46.8a13.4 13.4 0 0 1-9.2-3.8 13.4 13.4 0 0 1-3.8-9.3V74.5a13.4 13.4 0 0 1 3.8-9.3 13.4 13.4 0 0 1 9.2-3.8h33.7a13.4 13.4 0 0 1 9.3 3.8 13.4 13.4 0 0 1 3.8 9.3v28.9zm-46.8-28.9v15.9h33.7V74.5h-33.7zm54.3-13.1h17.3V39.6h13.1v21.8H681v13.1h-23.2V119H681v13.1h-23.2a13.4 13.4 0 0 1-9.2-3.8 13.4 13.4 0 0 1-3.8-9.3V74.5h-17.3V61.4zm78.1 13.1v57.6h-13V61.4h13v12.5a13.6 13.6 0 0 1 4-8.9 13.5 13.5 0 0 1 9.1-3.7h13.9a13.4 13.4 0 0 1 9.3 3.8 13.4 13.4 0 0 1 3.8 9.3v12h-13.1v-12h-27zm46.2-40.3h14.4v13.6h-14.4V34.2zm13.7 27.2v70.7h-13.1V61.4h13.1zm25.3 13.1V119h33.7v-12h13.1v12a13.4 13.4 0 0 1-3.8 9.3 13.4 13.4 0 0 1-9.3 3.8h-33.7a13.4 13.4 0 0 1-9.2-3.8 13.4 13.4 0 0 1-3.8-9.3V74.5a13.4 13.4 0 0 1 3.8-9.3 13.4 13.4 0 0 1 9.2-3.8h33.7a13.4 13.4 0 0 1 9.3 3.8 13.4 13.4 0 0 1 3.8 9.3v12h-13.1v-12h-33.7z"
                    className="st3"
                />
            </g>
        </svg>
    );
};

HivemetricLogo.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string
};

export default HivemetricLogo;
