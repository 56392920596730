// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { doc, onSnapshot } from 'firebase/firestore';

import { FirebaseContext } from './../../contexts/Firebase';
import { getVotingSettings } from './../../methods/getVotingSettings';
import { getPollConfig } from './../../methods/getPollConfig';
import { getResults } from './../../methods/getResults';
import { LayoutSection } from './../LayoutSection';
import { CurrentPollTitle } from './../CurrentPollTitle';
import Loading from './../Loading';
import StatsCard from './../Cards/Stats';
import ItemRow from './ItemRow';

// eslint-disable-next-line no-unused-vars
const d = require('debug')('suptuc:Results');

const StatsGrid = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;

const ResultsGrid = styled.section``;

export const Results = ({ narrow = false, leaderboard = false } = {}) => {
    const { db } = React.useContext(FirebaseContext);
    const [error, setError] = React.useState({});
    const [pollConfig, setPollConfig] = React.useState({});
    const [results, setResults] = React.useState({
        items: [],
        votes: 0,
        voters: 0
    });

    React.useEffect(() => {
        let unsub;
        let pollConfig = {};
        let results = {};
        getVotingSettings(db)
            // eslint-disable-next-line space-before-function-paren
            .then(async (votingSettings) => {
                pollConfig = await getPollConfig(votingSettings);
                results = await getResults(pollConfig);

                setPollConfig(pollConfig);
                setResults(results);
                // realtimeResults(votingSettings.poll);
                unsub = onSnapshot(votingSettings.poll, async (doc) => {
                    d(`New pollData snapshot`);
                    const tempResults = await getResults(doc.data());
                    setResults(tempResults);
                });
            })
            .catch((err) => setError(err));

        return () => {
            unsub?.();
        };
    }, [db]);

    const sortedOptions = React.useMemo(() => {
        const items = leaderboard
            ? results.items
                  .sort((a, b) => (b?.votes ?? 0) - (a?.votes ?? 0))
                  .slice(0, 4)
            : results.items;

        return items.map((item, i) => (
            <ItemRow
                key={`ir_${item.name}`}
                name={item.name}
                votes={item?.votes}
                alttheme={narrow}
                ratio={
                    !results?.voters
                        ? 0
                        : Math.floor((item.votes / results.voters) * 100, 2)
                }
            />
        ));
    }, [results, leaderboard]);

    if (!Object.keys(pollConfig).length || !results?.items.length) {
        return <Loading />;
    }

    if (Object.keys(error).length) {
        return (
            <pre>
                Something went wrong... please try again.
                <br />
                Error: {JSON.stringify(error, null, 2)}
            </pre>
        );
    }

    return (
        <LayoutSection narrow={narrow}>
            {!leaderboard ? (
                <CurrentPollTitle>{pollConfig.name}</CurrentPollTitle>
            ) : null}
            <StatsGrid>
                <StatsCard
                    title={`Votes Cast`}
                    alttheme={narrow}
                    value={results.votes}
                />
                {/* <StatsCard
                    title={`Voters`}
                    alttheme={narrow}
                    value={results.voters}
                /> */}
            </StatsGrid>
            <ResultsGrid>{sortedOptions}</ResultsGrid>
        </LayoutSection>
    );
};

Results.propTypes = {
    narrow: PropTypes.bool,
    leaderboard: PropTypes.bool
};

export default Results;
