import { collection, getDocs } from 'firebase/firestore';

// eslint-disable-next-line no-unused-vars
const d = require('debug')('suptuc:getPolls');
// eslint-disable-next-line space-before-function-paren
export const getPolls = async (db) => {
    try {
        const pollsRef = collection(db, 'polls');
        const pollsSnapshot = await getDocs(pollsRef);
        const polls = [];

        pollsSnapshot.forEach((pollDoc) => {
            polls.push({ ...pollDoc.data(), id: pollDoc.id });
        });

        return polls;
    } catch (err) {
        d('Failed to get poll documents: %O', err);
        return err;
    }
};
