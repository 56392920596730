import styled, { css } from 'styled-components';

export const LayoutSection = styled.div`
    display: flex;
    flex-direction: column;
    ${({ narrow }) =>
        narrow &&
        css`
            /* max-width: 50%; */
            flex: 1;
        `}
`;
