import React from 'react';
import { ThemeProvider } from 'styled-components';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Modal from 'react-modal';

import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import defaultTheme from './styles/theme';
import { FirebaseProvider } from './contexts/Firebase';
import { GlobalStyleOverrides } from './styles/globalStyleOverrides';

// Pages
import AdminPage from './pages/admin';
import ResultsPage from './pages/results';
import ResultsAndInstructionsPage from './pages/resultsAndInstructions';
import Error404Page from './pages/404';
import IndexPage from './pages';

import 'react-alice-carousel/lib/alice-carousel.css';

const root = createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
Modal.setAppElement('#root');

root.render(
    <FirebaseProvider>
        <QueryClientProvider client={queryClient}>
            <Helmet
                defaultTitle="Startup Tucson's IdeaFunding People's Choice Award"
                titleTemplate="%s | Powered by Hivemetric"
                defer={false}
            />
            <ThemeProvider theme={defaultTheme}>
                <GlobalStyleOverrides />
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<App />}>
                            <Route index element={<IndexPage />} />
                            <Route path="kanrisha" element={<AdminPage />} />
                            <Route path="results" element={<ResultsPage />} />
                            <Route
                                path="results-and-instructions"
                                element={<ResultsAndInstructionsPage />}
                            />
                            <Route path="404" element={<Error404Page />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </QueryClientProvider>
    </FirebaseProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
