// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import {
    app,
    remoteConfig,
    auth,
    db,
    functions
} from './../../Firebase/config';

// eslint-disable-next-line no-unused-vars
const d = require('debug')('suptuc:FirebaseContext');

export const FirebaseContext = React.createContext();

export const FirebaseProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);

    React.useEffect(() => {
        app?.auth?.().onAuthStateChanged(setUser);
    }, []);

    return (
        <FirebaseContext.Provider
            value={{
                app,
                remoteConfig,
                auth,
                db,
                user,
                functions
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};
