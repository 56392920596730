// eslint-disable-next-line no-unused-vars
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const HeaderActual = styled.header`
    margin: 0 0 0.5rem 0;
    background-color: ${(props) => props.theme.colors.text.default};
    ${(props) => props.theme.responsive.device} {
        margin-bottom: 0;
    }
`;

const IdeaFundingLogo = styled.img`
    width: 8rem;
    ${(props) => props.theme.responsive.device} {
        width: 6rem;
        margin-bottom: 0.75rem;
    }
`;

const Navigation = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    /* margin: 1rem 0 0 0; */
    width: 18rem;
    ${(props) => props.theme.responsive.device} {
        width: 100%;
        background: ${({ theme }) => theme.colors.background.tertiary};
    }
`;
const activeClassName = 'active';
const CustomNavLink = styled(NavLink).attrs({
    activeclassname: activeClassName
})`
    border-bottom: 2px solid rgba(0, 0, 0, 0.067);
    /* color: ${(props) => props.theme.colors.text.default}; */
    color: ${(props) => props.theme.colors.fixed.white};
    font-size: 1rem;
    font-weight: 400;
    padding: 0.42rem 0;
    text-align: center;
    text-decoration: none;
    &.${activeClassName} {
        border-color: ${({ theme }) => theme.colors.brand.primary};
    }
    ${(props) => props.theme.responsive.device} {
        width: 100%;
        color: ${({ theme }) => theme.colors.text.default};
        &.${activeClassName} {
            color: ${({ theme }) => theme.colors.brand.primary};
            border-color: ${({ theme }) => theme.colors.brand.primary};
        }
    }
    ${({ desktop, theme }) =>
        desktop === 'true' &&
        css`
            ${(props) => props.theme.responsive.device} {
                display: none;
            }
        `}
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 0.75rem 0;
    justify-content: space-between;
    ${(props) => props.theme.responsive.device} {
        /* padding: 0 1.337rem; */
        padding-bottom: 0;
        flex-direction: column;
        align-items: center;
    }
`;

export const Header = () => {
    return (
        <HeaderActual>
            <ContentWrapper>
                <IdeaFundingLogo
                    src={`${process.env.PUBLIC_URL}/TW-HORZ-MARK-ST-2023.png`}
                    alt={`IdeaFunding powered by Startup Tucson`}
                />
                {/* <IdeaFundingLogo
                src={`/img/logos/ideafunding.png`}
                alt={`IdeaFunding powered by Startup Tucson`}
            /> */}
                <Navigation>
                    <CustomNavLink to={`/`} end>
                        How to Vote
                    </CustomNavLink>
                    <CustomNavLink to={`/results`} end>
                        Results
                    </CustomNavLink>
                    <CustomNavLink
                        to={`/results-and-instructions`}
                        end
                        desktop={'true'}
                    >
                        Split Screen
                    </CustomNavLink>
                </Navigation>
            </ContentWrapper>
        </HeaderActual>
    );
};

export default Header;
