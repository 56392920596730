import { getDoc } from 'firebase/firestore';

// eslint-disable-next-line no-unused-vars
const d = require('debug')('suptuc:getPollConfig');
// eslint-disable-next-line space-before-function-paren
export const getPollConfig = async (votingSettingsRef) => {
    try {
        const settingsDoc = await getDoc(votingSettingsRef.poll);

        if (settingsDoc.exists()) {
            const pollConfig = settingsDoc.data();

            d('Got pollConfig, %O', pollConfig);
            return pollConfig;
        } else {
            throw new Error(`pollConfig doesn't exist, or can't be fetched`);
        }
    } catch (err) {
        d('Failed to get pollConfig: %O', err);
        return err;
    }
};
