export default {
    colors: {
        background: {
            default: `#FFFFFF`,
            tertiary: `#f3fbff`,
            secondary: `#d3edf4`
            // default: `#FFFFFF`,
            // tertiary: `#DAE3E5`,
            // secondary: `#BBD1EA`
        },
        border: {
            secondary: `#a0c1dd`,
            tertiary: `#d8e8ef`
            // secondary: `#a0c1dd`,
            // tertiary: `#cedce4`
        },
        brand: {
            primary: `#299e8d`,
            secondary: `#37b09e`
        },
        text: {
            default: `#425A70`,
            muted: `#66788A`,
            dark: `#234361`
        },
        fixed: {
            white: `#fff`
        },
        danger: `#FC5130`
    },
    responsive: {
        desktop: `@media (min-width: 770px)`,
        device: `@media (min-width: 320px) and (max-width: 769px)`,
        sff: `@media (max-width: 479px)`
    }
};
